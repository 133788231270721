import React, {useState} from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid,Box,AspectImage,AspectRatio,Card,Button,Image } from 'theme-ui'
import intro from "../images/intro.jpg"
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import logo from "../images/logo.png"

function IndexPage({data}) {
  const about = data.allContentfulAbout.edges[0].node
  const title = data.allContentfulAbout.edges[0].node.title
  const books = data.allContentfulBooks.edges
  
const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const assets = new Map(data.allContentfulAbout.edges[0].node.introduction.references.map(ref => [ref.contentful_id,ref]))

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode:  {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return(
        <Box className="intro-text"> 
        <Text>{children}</Text>
        </Box>
      )
        },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const url = assets.get(node.data.target.sys.id).fixed.src
      const alt = assets.get(node.data.target.sys.id).title
      return (
        <AspectRatio  ratio={4 / 3} className="intro-img">
          <Bounce top>
            <AspectImage variant="avatar" ratio={4 / 3}  src={url} />
          </Bounce>
        </AspectRatio >
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) =>{
      const url = assets.get(node.data.target.sys.id).books.fixed.src
      const slug = assets.get(node.data.target.sys.id).slug
      const title = assets.get(node.data.target.sys.id).title
      const content = assets.get(node.data.target.sys.id).childContentfulBooksIntroductionTextNode.introduction
      return(
        <Grid className="best-seller"  gap={2} columns={[2, '1fr 2fr']}>
          <img className="best-seller-img" src={url} />
          <Card  className="best-seller-intro-card">
            <Text><h4>{title}</h4></Text>
              <Text>{content}</Text>
              <Link to={"/books/" + slug}>
              <Button className="books-excerpt-button"> Read more </Button>
              </Link>
          </Card>
        </Grid>
      )
    }

  }
}

  return(
    <Layout>
      <Grid className="layout-main" columns={[2, '0.2fr 0.9fr']}>
      {documentToReactComponents(JSON.parse(data.allContentfulAbout.edges[0].node.introduction.raw),options)}
      {/* <img src ={intro} /> */}
      </Grid>
      <Grid gap={2} columns={[2, '1fr 5fr']}>
      <Box style={{display:"grid",height:"100%",}}>
        <img style= {{margin: "auto"}} variant="avatar"  src={logo}/>
      </Box>
      <Box style={{display:"grid",height:"100%"}}>
      <Fade left>
        <h2 style= {{margin: "auto"}}>{title} & Ron Scollon</h2>
      </Fade>
      </Box>
      </Grid>
      <Grid className="books-display-page"  gap={2} columns={[2, null, 4]}>
          {data.allContentfulBooks.edges.map(( {node} )=>{
            return(
              <Link to={"/books/" + node.slug}>
            <Card className="books-display">
            <Flip top>
            <Image ratio={16 / 8}  src={node.books.fixed.src} />
            </Flip>
            <Text>
              {node.title}
              </Text>
            </Card>
            </Link>
            )
          })
        }
      </Grid>
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query{
    allContentfulAbout {
      edges {
        node {
          title
          introduction {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                fixed(width: 150, height: 150) {
                  width
                  height
                  src
                  srcSet
                }        
              }
              ... on ContentfulBooks {
                contentful_id
                __typename
                id
                title
                slug
                books {
                  fixed(width: 150,height: 250) {
                    width
                    height
                    src
                    srcSet
                  }
                }
                contentful_id
                childContentfulBooksIntroductionTextNode {
                  introduction
                }
              }
            }
          }
        }
      }
    }
    allContentfulBooks(limit: 4) {
      edges {
        node {
          title
          slug
          contentful_id
          books {
            id
            fixed(width: 150,height:250) {
              src
            }
          }
        }
      }
    }

  }
`